<template>
  <div>
    <v-card flat class="segmented">
      <v-card-text>
        <feedbacks-table
          :feedbacks="feedbacks"
          :loading="loading"
          :statusQuery="statusQuery"
          :processTypeQuery="processTypeQuery"
          :keywordQuery="keyword"
          searchable
          @process-created="loadFeedbacks"
        ></feedbacks-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import feedbacksTable from '@/components/feedbacks/feedbacks-table.vue';

export default {
  data() {
    return {
      feedbacks: [],
      loading: true,
      statusQuery: null,
      processTypeQuery: null
    };
  },

  props: {
    status: {
      type: String,
      required: false,
      default: ''
    },

    type: {
      type: String,
      required: false,
      default: ''
    },

    keyword: {
      type: String,
      required: false,
      default: ''
    },

    indirect: {
      type: String,
      required: false,
      default: ''
    }
  },

  computed: {
    isVertical() {
      const bpName = this.$vuetify.breakpoint.name;
      return bpName === 'xs' || bpName === 'sm';
    }
  },

  async mounted() {
    if (this.type && this.type.length > 0) {
      this.processTypeQuery = [parseInt(this.type)];
      if (this.status && this.status.length > 0) {
        const statuses = await this.mapStatus({
          processType: this.type,
          statusId: this.status
        });
        this.statusQuery = statuses.map((x) => ({
          processType: parseInt(this.type),
          statusId: x
        }));
      }
    }

    this.$usersHub.$on('donor-processes-updated', this.loadFeedbacks);
    this.loadFeedbacks();
  },

  beforeDestroy() {
    this.$usersHub.$off('donor-processes-updated', this.loadFeedbacks);
  },

  methods: {
    ...mapActions({
      listFeedbacks: 'feedbacks/listAllFeedbacks',
      listPdps: 'pdp/listAll',
      mapStatus: 'workflows/mapStatus'
    }),

    async loadFeedbacks() {
      this.loading = true;
      var requests = [this.listFeedbacks(), this.listPdps()];
      var feedbacks = await Promise.all(requests);
      this.feedbacks = feedbacks.flat();
      this.loading = false;
    }
  },

  components: {
    feedbacksTable
  }
};
</script>
